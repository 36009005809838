import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import CurrencyDollarIcon from "@heroicons/react/24/outline/CurrencyDollarIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import BookmarkFilledIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import WorkIcon from "@mui/icons-material/Work";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";
import { ToastContainer } from "react-toastify";

export const FontAwesomeIconStyled = styling(FontAwesomeIcon)((props) => ({
  height: "100%",
  width: "100%",
}));

// Box Component //
export const JobContentInnerContainer = withTheme(styled(Box)`
  && {
    width: 70%;
    margin-right: 20px;

    ${(props) => props.theme.breakpoints.between("md", "lg")} {
      width: 60%;
    }

    ${(props) => props.theme.breakpoints.between("tablet", "md")} {
      width: 50%;
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: 100%;
      margin-top: 20px;
    }
  }
`);

// Grid Components //
export const JobPageContainer = styling("div")((props) => ({
  maxWidth: "1440px",
  width: "90%",
  margin: "auto",
  marginTop: "calc(68px)",
  WebkitOverflowScrolling: "touch",

  [props.theme.breakpoints.down("xl")]: {
    margin: "auto",
  },

  [props.theme.breakpoints.down("lg")]: {
    width: "96%",
    padding: "0",
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "100%",
    paddingInline: "16px",
  },

  "@media (max-width: 1919px)": {
    marginTop: "calc(68px)",
  },

  "@media (max-width: 1279px)": {
    marginTop: "calc(68px + 24px)",
  },

  "@media (max-width: 640px)": {
    marginTop: "calc(68px + 52px)",
  },
}));

export const JobCompanySection = withTheme(styled(Grid)`
  && {
    height: 100%;
    position: relative;
  }
`);

export const MobileBottomBar = withTheme(styled(Grid)`
  && {
    position: sticky;
    z-index: 15;
    bottom: 0;
    padding: 20px 5%;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: ${Color.white};
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px 20px 0 0;
  }
`);

export const IconButtonContainer = styled(Grid)`
  && {
    background: ${Color.white};
    border-radius: 30px;
    margin-left: 5px;
  }
`;

export const OurBenefitContainer = withTheme(styled(Grid)`
  && {
    max-width: 1280px;
    margin: auto;
  }
`);

export const OurMediaSection = withTheme(styled(Grid)`
  && {
    max-width: 1280px;
    margin: auto;
  }
`);

export const RecommendedJobsContainer = withTheme(styled(Grid)`
  && {
    max-width: 1280px;
    margin: auto;
  }
`);

export const JobCompanyWrapper = withTheme(styled(Grid)`
  && {
    border-radius: 20px;
    background: ${Color.white};
    padding: 30px 40px;
  }
`);

// Button Components //
export const DiscoverButton = withTheme(styled(Button)`
  && {
    width: 12.75rem;
    color: ${Color.white};
    background: ${Color.hiredlyPurple};
    height: 2.75rem;
    border-radius: 2rem;
    font-weight: bold;
    text-transform: capitalize;

    & :hover {
      background: ${Color.darkPurple};
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: 0.75rem;
      width: 6.25rem;
      height: 2rem;
    }
  }
`);

export const BannerContentText = styled(Typography)`
  && {
    //
  }
`;

// Icon Components //
export const WorkIconStyled = withTheme(styled(WorkIcon)`
  && {
    margin-right: ${(props) => (props.$banner ? `1.5rem` : `24px`)};
    font-size: ${(props) => (props.$banner ? `3rem` : FontSize.desktopBody)};
    color: ${(props) => (props.$banner ? Color.hiredlyPurple : null)};

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: 1.5rem;
    }
  }
`);

export const MenuIconStyled = styled(MenuIcon)`
  && {
    margin-right: 10px;
    font-size: ${FontSize.desktopBody};
  }
`;

export const BookmarkBorderOutlinedIconStyled = styled(
  BookmarkBorderOutlinedIcon
)`
  && {
    color: ${Color.black};
  }
`;

export const BookMarkFilledIconStyled = styled(BookmarkFilledIcon)`
  && {
    color: ${Color.hiredlyPurple};
  }
`;

// Progress Components //
export const CircularProgressStyled = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      width: 24px !important;
      height: 24px !important;
    }
  }
`;

// Div Components //
export const SimilarJobPostingBanner = withTheme(styled.div`
  && {
    display: flex;
    box-shadow: 0px 4px 18px rgba(81, 42, 204, 0.2);
    border-radius: 30px;
    width: 55%;
    min-height: 80px;
    margin: 1rem 0;
    justify-content: space-around;
    padding: 0.5rem;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: 100%;
      min-height: 4rem;
    }
  }
`);

export const SimilarJobPostingBannerDiv = withTheme(styled.div`
  && {
    display: flex;
    justify-content: center;
    width: 100%;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: 96%;
      margin: 0 auto;
    }
  }
`);

export const BannerContent = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// Reduce width of card from 100% to 45% and from 60% to 30% respectively
export const JobHighlightContainer = withTheme(styled.div`
  && {
    position: sticky;
    top: 90px;
    z-index: 2;
    width: 45%;
    height: 100%;

    ${(props) => props.theme.breakpoints.up("lgXl")} {
      width: 30%;
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      position: relative;
      top: 0;
      z-index: 1;
      width: 100%;
    }
  }
`);

export const JobContentContainer = withTheme(styled.div`
  display: flex;

  ${(props) => props.theme.breakpoints.down("tablet")} {
    display: flex;
    flex-direction: column-reverse;
  }
`);

export const Wrapper = styled.div`
  && {
    background: ${Color.backgroundGrey};
    min-height: 50vh;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  && {
  }
`;

export const JobAlertContainer = styling(Box)({
  position: "fixed",
  bottom: "0",
  width: "100%",
  zIndex: "20",
});

export const ArrowIconStyled = styling(ArrowTopRightOnSquareIcon)({
  height: "19px",
  width: "20px",
  margin: "0 -6px 0 6px",
});

export const ImageStyled = styling("img")((props) => ({
  objectFit: "cover",
  borderRadius: "8px",
  marginRight: "20px",
}));

export const DivStyled = styling(Grid)((props) => ({
  //
}));

export const BoxStyled = styling(Box)((props) => ({
  display: "flex",
}));

export const TypographyStyled = styling(Typography)((props) => ({
  //
}));

export const CardStyled = styling(Card)((props) => ({
  borderRadius: "10px",
  height: "100%",
  boxShadow: "0 0 0 rgb(181 181 181 / 20%)",
  height: "fit-content",
}));

export const LabelStyled = styled(Typography)`
  && {
    width: 100%;
    line-height: 1.4;
    color: ${Color.black};
    font-size: ${FontSize.desktopBody};
    letter-spacing: 0.05rem;
  }
`;

export const CardContentStyled = styling(CardContent)((props) => ({
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingBottom: "16px !important",
}));

export const GridStyled = styling(Grid)((props) => ({
  //
}));

export const MapPinStyled = styling(MapPinIcon)((props) => ({
  width: 24,
  height: 24,
}));

export const CurrencyIconStyled = styling(CurrencyDollarIcon)((props) => ({
  width: 24,
  height: 24,
}));

export const IconTextContainer = styling(Grid)((props) => ({
  display: "flex",
  alignItems: "center",
}));

export const ApplyContainer = styling(Box)((props) => ({
  position: "fixed",
  bottom: "0",
  width: "100%",
  zIndex: "10",
  visibility: "hidden",
  borderTop: `1.5px solid ${Color.black}`,
  backgroundColor: Color.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "70px",
  padding: "10px 0px",

  [props.theme.breakpoints.down("lg")]: {
    visibility: "visible",
  },
}));

export const ToastStyled = styled(ToastContainer)({
  
  "&.Toastify__toast-container": {
    width: "15.625rem",
    padding: "0.625rem 0.5rem 0.5rem 1rem",
    position: "fixed",
    top: "67px !important",
    right: "0 !important",

    "@media(max-width: 480px)": {
      left: "auto",
    }
  },

  "& .Toastify__toast-body": {

    padding: 0,
    margin: 0,
    height: "fit-content",
  },

  "& .Toastify__toast": {
    color: Color.success,
    border: `1px solid ${Color.success}`,
    borderRadius: "0.5rem",

    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.25rem",

    minHeight: "fit-content",
    maxHeight: "38px !important",

    width: "100%",
    maxWidth: "220px",

    marginBottom: "10px",

    padding: "10px 8px 8px 16px",
    // width: "15.625rem",
    // "0.625rem 0.5rem 0.5rem 1rem",
  },

  "& .Toast-success":{
    border: `1px solid ${Color.success}`,
    color: Color.success,
    "--toastify-color-progress-light": Color.success,
  },
  "& .Toast-error":{
    border: `1px solid ${Color.error}`,
    color: Color.error,
    "--toastify-color-progress-light": Color.error,
  }
});
